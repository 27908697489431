import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.report.ramgps
      // component: lazy(() => import('src/views/blog/NewPostView'))
    },

    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.report.root} />
    },
    {
      exact: true,
      path: PATH_APP.report.history,
      component: lazy(() => import('src/views/mapComponent/history'))
    },
    {
      exact: true,
      path: PATH_APP.report.model,
      component: lazy(() => import('src/views/ManageModel'))
    },
    {
      exact: true,
      path: PATH_APP.report.editModel,
      component: lazy(() => import('src/views/ManageModel/editModelIndex'))
    },
    //live booking
    {
      exact: true,
      path: PATH_APP.report.newBooking,
      // component: lazy(() => import('src/views/app/ChatView/index')),
      component: lazy(() => import('src/views/NewBooking'))
    },
    {
      exact: true,
      path: PATH_APP.report.profileView,
      // component: lazy(() => import('src/views/app/ChatView/index')),
      component: lazy(() => import('src/views/Profile'))
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
