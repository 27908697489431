/* eslint-disable prettier/prettier */
// import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
// import SelectInput from '@material-ui/core/Select/SelectInput';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11,
  testPassNode: []
};

const slice = createSlice({
  name: 'dash',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },
    getVehicleDataSuccess(state, action) {
      state.isLoading = false;
      state.vehicleCount = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    },

    TestPassNodeSucess(state, action) {
      state.isLoading = false;
      state.testPassNode = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPostsInitial(
  date,
  month,
  year,
  toDate,
  toMonth,
  toYear,
  Filter
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //dispatch(slice.actions.getPostsInitial());
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          date: date,
          month: month,
          year: year,
          toDate: toDate,
          toMonth: toMonth,
          toYear: toYear,
          companyId: Filter
        })
      };

      fetch('http://15.184.158.127:8809/api/v1/shiftListRange', requestOptions)
        .then((response) => response.json())
        .then((data) => dispatch(slice.actions.getPostsInitial(data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVehicleInfo(Filter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          companyId: Filter
        })
      };

      fetch('http://15.184.158.127:3004/georeport/vehicleCount', requestOptions)
        .then((response) => response.json())
        .then((data) => dispatch(slice.actions.getVehicleDataSuccess(data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function TestPassNode(Filter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        Authorization: '0003ec7c2a11e8ec20d622b83f95831a'
      };

      fetch(
        'https://passnodeauth.limor.us/passenger/getAccessToken',
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => dispatch(slice.actions.TestPassNodeSucess(data)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
