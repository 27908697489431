import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { MAvatar } from 'src/theme';
import createAvatar from 'src/utils/createAvatar';
import { useEffect } from 'react';
import { useState } from 'react';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  className: PropTypes.string
};

function MyAvatar({ className, ...other }) {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  useEffect(() => {
    const items = JSON.parse(sessionStorage.getItem('corporate'));
    if (items) {
      setName(items.name);
      setImage(items.corporateLogo);
    }
  }, []);

  return (
    <MAvatar
      src={`https://rsllive.s3.me-south-1.amazonaws.com/corporate/${image}`}
      alt="image"
      // color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
      className={className}
      {...other}
    >
      {createAvatar(name).name}
    </MAvatar>
  );
}

export default MyAvatar;
