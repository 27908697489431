import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP } from 'src/routes/paths';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  elements: <MIcon src={path('ic_elements')} />
};

const navConfig = [
  {
    subheader: 'Report',
    items: [
      {
        title: 'Report',
        icon: ICONS.elements,
        href: PATH_APP.report.newBooking,
        items: [
          {
            title: 'New Booking',
            href: PATH_APP.report.newBooking
          }
        ]
      }
    ]
  }
];

export default navConfig;
