/* eslint-disable prettier/prettier */
/* eslint-disable eqeqeq */
import { map } from 'lodash';
// import axios from 'src/utils/axios';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null
};

//Commit check
// const live_url_old = 'http://15.184.158.127:3004';
// const live_url = 'http://15.184.188.198:3004';
const live_url = 'https://corporateback.royalsmartlimousine.com';
const demo_url = 'http://34.197.71.192:3009';
const newBookingUrlLive = 'https://corporateback.royalsmartlimousine.com';

const newBookingUrl = live_url;

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    //driver status

    getPassengerDetailSuccess(state, action) {
      state.isLoading = false;
      state.passengerDetailList = action.payload;
    },

    getTopDriverInfoSucess(state, action) {
      state.isLoading = false;
      state.topDriverInfoData = action.payload;
    },

    //supervisor

    getSupervisorInfoSucess(state, action) {
      state.isLoading = false;
      state.supervisorData = action.payload;
    },

    getSupervisorDetailsInfoSucess(state, action) {
      state.isLoading = false;
      state.supervisorDetailsData = action.payload;
    },
    getPushNotificationInfoSucess(state, action) {
      state.isLoading = false;
      state.pushNotification = action.payload;
    },
    getPushNotificationInfo1Sucess(state, action) {
      state.isLoading = false;
      state.pushNotification1 = action.payload;
    },
    getPassengerDataSucess(state, action) {
      state.isLoading = false;
      state.PassengerData = action.payload;
    },
    getSupervisorMonitorLogSucess(state, action) {
      state.isLoading = false;
      state.supervisorMonitorLogData = action.payload;
    },
    getSupervisorMonitorSucess(state, action) {
      state.isLoading = false;
      state.supervisorMonitorData = action.payload;
    },
    getCustomZoneSucess(state, action) {
      state.isLoading = false;
      state.customZoneData = action.payload;
    },
    getCustomZoneAllSucess(state, action) {
      state.isLoading = false;
      state.customZoneAllData = action.payload;
    },
    getPassengerData1Sucess(state, action) {
      state.isLoading = false;
      state.passengerAllData = action.payload;
    },
    getPassengerPushAllSucess(state, action) {
      state.isLoading = false;
      state.passengerPushAllData = action.payload;
    },
    getPassengerDetailsSucess(state, action) {
      state.isLoading = false;
      state.getByPassengerData = action.payload;
    },

    //kiosk
    getKioskListSucess(state, action) {
      state.isLoading = false;
      state.kioskData = action.payload;
    },
    getKioskListByIdSucess(state, action) {
      state.isLoading = false;
      state.kioskListByData = action.payload;
    },
    getKioskDataUpdateSucess(state, action) {
      state.isLoading = false;
      state.kioskUpdateData = action.payload;
    },

    //new booking
    getCompanyListSucess(state, action) {
      state.isLoading = false;
      state.companyAllList = action.payload;
    },
    getCompanyListAllSucess(state, action) {
      state.isLoading = false;
      state.companyListAll = action.payload;
    },
    getCarListSucess(state, action) {
      state.isLoading = false;
      state.carAllList = action.payload;
    },
    getCarMakeListSucess(state, action) {
      state.isLoading = false;
      state.carMakeAllList = action.payload;
    },
    getCorporateListSucess(state, action) {
      state.isLoading = false;
      state.corporateAllList = action.payload;
    },
    getUpcomingBookingSucess(state, action) {
      state.isLoading = false;
      state.upcomingBookingList = action.payload;
    },
    getUpcomingBookingSucessCsv(state, action) {
      state.isLoading = false;
      state.upcomingBookingListCsv = action.payload;
    },
    getBookingPriceSucess(state, action) {
      state.isLoading = false;
      state.bookingPriceUpdate = action.payload;
    },
    getCancelBookingSucess(state, action) {
      state.isLoading = false;
      state.cancelBookingData = action.payload;
    },
    getByPassengerSucess(state, action) {
      state.isLoading = false;
      state.passengerDetails = action.payload;
    },
    getByCarSucess(state, action) {
      state.isLoading = false;
      state.carDetails = action.payload;
    },
    getByCarMakeSucess(state, action) {
      state.isLoading = false;
      state.carMakeDetails = action.payload;
    },
    getByPassengerCardSucess(state, action) {
      state.isLoading = false;
      state.passengerCardData = action.payload;
    },
    getByEditUpdateSucess(state, action) {
      state.isLoading = false;
      state.editDatas = action.payload;
    },
    getByNearestDriverSucess(state, action) {
      state.isLoading = false;
      state.nearestDriverList = action.payload;
    },
    getByActionDriverDataSucess(state, action) {
      state.isLoading = false;
      state.actionDriverList = action.payload;
    },
    getExitRecordInfoSucess(state, action) {
      state.isLoading = false;
      state.exitRecord = action.payload;
    },
    getByAddPassengerSucess(state, action) {
      state.isLoading = false;
      state.insertPassenger = action.payload;
    },
    getByEditPassengerSucess(state, action) {
      state.isLoading = false;
      state.updatePassenger = action.payload;
    },
    getDriverDocumentSucess(state, action) {
      state.isLoading = false;
      state.driverDocumentList = action.payload;
    },
    getByPassengerDataSucess(state, action) {
      state.isLoading = false;
      state.passengerRecordList = action.payload;
    },
    getDriverFilterDocumentSucess(state, action) {
      state.isLoading = false;
      state.driverFilterDocumentList = action.payload;
    },
    getDriverFilterDocumentStatusSucess(state, action) {
      state.isLoading = false;
      state.driverFilterDocumentStatusData = action.payload;
    },
    getByCorporateDataSucess(state, action) {
      state.isLoading = false;
      state.corporateRecordList = action.payload;
    },
    getByPackageDataSucess(state, action) {
      state.isLoading = false;
      state.packageRecordList = action.payload;
    },
    getByMotorCarListSucess(state, action) {
      state.isLoading = false;
      state.motorList = action.payload;
    },
    getByFareDetailsSucess(state, action) {
      state.isLoading = false;
      state.fareAllDetails = action.payload;
    },
    getAllCarMakesSucess(state, action) {
      state.isLoading = false;
      state.allCarMakesData = action.payload;
    },
    getCarMakeFareSucess(state, action) {
      state.isLoading = false;
      state.carMakeFareData = action.payload;
    },
    getCorporatePackageListSucess(state, action) {
      state.isLoading = false;
      state.corporatePackageListData = action.payload;
    },
    getBySaveBookingSucess(state, action) {
      state.isLoading = false;
      state.saveBookingResponse = action.payload;
    },
    getByAssignDriverDataSucess(state, action) {
      state.isLoading = false;
      state.assignDriverData = action.payload;
    },
    getByOverViewSucess(state, action) {
      state.isLoading = false;
      state.overviewAllData = action.payload;
    },
    getByEndTripSucess(state, action) {
      state.isLoading = false;
      state.endTripData = action.payload;
    },
    getByCurrentDriverLocationSucess(state, action) {
      state.isLoading = false;
      state.currentDriverLocation = action.payload;
    },
    getLocationGroupSucess(state, action) {
      state.isLoading = false;
      state.locationListAll = action.payload;
    },
    getAddRecordInfoSucess(state, action) {
      state.isLoading = false;
      state.locationGroupInfo = action.payload;
    },
    getEditRecordInfoSucess(state, action) {
      state.isLoading = false;
      state.editLocationGroupInfo = action.payload;
    },
    getLocationStatusUpdateSucess(state, action) {
      state.isLoading = false;
      state.statusLocationGroupInfo = action.payload;
    },
    getChatListSucess(state, action) {
      state.isLoading = false;
      state.chatRecords = action.payload;
    },
    getChatListUpdateSucess(state, action) {
      state.isLoading = false;
      state.chatRecordsUpdate = action.payload;
    },
    getSendDriverSmsSuccess(state, action) {
      state.isLoading = false;
      state.msgSendResponce = action.payload;
    },
    getLoginFormSuccess(state, action) {
      state.isLoading = false;
      state.loginResponse = action.payload;
    },
    getResetLoginFormSuccess(state, action) {
      state.isLoading = false;
      state.resetLoginResponse = action.payload;
    },
    getChangeLoginFormSuccess(state, action) {
      state.isLoading = false;
      state.changeUserPassResponse = action.payload;
    },
    getProfileDetailsSuccess(state, action) {
      state.isLoading = false;
      state.profileDetailsRes = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/user/account/notifications-settings'
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// driver status

export function getBookingDetail(values, datas) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var reqBody = {
      fromDate: datas.fromDate,
      toDate: datas.toDate,
      type: values
    };
    try {
      const response = await axios.post(
        'http://54.196.74.135:5050/api/getMobileAppTripCopy',
        reqBody
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getPassengerDetailSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// New Booking

export function getCompanyList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${newBookingUrl}/newBooking/companyListRestrict`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyListAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${newBookingUrl}/newBooking/companyListAll`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyListAllSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCarList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`${newBookingUrl}/newBooking/carList`);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCarMakeList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${newBookingUrl}/newBooking/getCarMakeList`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakeListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCorporateList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${newBookingUrl}/newBooking/corporateList`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCorporateListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUpcomingBookingList(values, type, pageNumber, pageLimit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const items = JSON.parse(sessionStorage.getItem('corporate'));
    // setFilter({ ...filter, ['corporate']: items.id });
    // console.log(items.id);
    const obj = {
      bookingStatus: values.bookingStatus,
      bookingType: values.bookingType,
      bookingFrom: values.bookingFrom,
      // createdFrom: values.createdFrom,
      createdFrom: 7,
      company: values.company,
      carType: values.carType,
      // corporate: 146,
      corporate: items.id,
      fromDate: moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      toDate:
        values.toDate !== null
          ? moment(new Date(values.toDate)).format('YYYY-MM-DD HH:mm:ss')
          : ' ',
      tripId: values.tripId !== 0 ? parseInt(values.tripId) : ' ',
      search2: values.search2,
      type: type,
      pageNumber: pageNumber,
      pageLimit: pageLimit
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/upcomingBookingCorporate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getUpcomingBookingSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getUpcomingBookingListCsv(values, type, pageNumber, pageLimit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const items = JSON.parse(sessionStorage.getItem('corporate'));
    // setFilter({ ...filter, ['corporate']: items.id });
    // console.log(items.id);
    const obj = {
      bookingStatus: values.bookingStatus,
      bookingType: values.bookingType,
      bookingFrom: values.bookingFrom,
      // createdFrom: values.createdFrom,
      createdFrom: 7,
      company: values.company,
      carType: values.carType,
      // corporate: 146,
      corporate: items.id,
      fromDate: moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      toDate:
        values.toDate !== null
          ? moment(new Date(values.toDate)).format('YYYY-MM-DD HH:mm:ss')
          : ' ',
      tripId: values.tripId !== 0 ? parseInt(values.tripId) : ' ',
      search2: values.search2,
      type: type,
      pageNumber: pageNumber,
      pageLimit: pageLimit
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/upcomingBookingCorporate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getUpcomingBookingSucessCsv(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBookingPrice(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value.id,
      value: parseInt(value.value),
      filter: value.filter
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/bookingPriceUpdate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getBookingPriceSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCancelBooking(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value.id
      // reason: value.reason,
      // travelStatus: 8
    };
    console.log(obj);

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/corporateTripCancel`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCancelBookingSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByPassenger(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/getByPassengerEditDetails`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByPassengerSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByCar(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/getByCarDetails`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByCarSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByCarMake(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/getByCarMakeDetails`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByCarMakeSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByTaxi(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/getByCarDetails`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByCarSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByPassengerCard(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/getByPassengerDetails`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByPassengerCardSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editUpdate(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    // const pickupDate = moment(new Date(pickupTime)).format(
    //   'YYYY-MM-DDTHH:mm:ss.000Z'
    // );
    // const obj = {
    //   id: value._id,
    //   pickup_time: pickupDate,
    //   motor_model_info: value.motor_model_info,
    //   custom_price: {
    //     customer_price: value.customer_price,
    //     customer_hourly_rate: value.customer_hourly_rate,
    //     driver_price: value.driver_price,
    //     driver_hourly_rate: value.driver_hourly_rate
    //   },
    //   note_to_driver: value.note_to_driver,
    //   flight_number: value.flight_number,
    //   reference_number: value.reference_number,
    //   note_to_admin: value.note_to_admin,
    //   current_location: value.current_location,
    //   drop_location: value.drop_location,
    //   pickup_notes: value.pickup_notes,
    //   drop_notes: value.drop_notes,
    //   payment_method: value.payment_method,
    //   payment_by: value.payment_by,
    //   passenger_payment_option: value.passenger_payment_option,
    //   final_payment_option: value.final_payment_option,
    //   pickup_latitude: pickupLatlng.lat,
    //   pickup_longitude: pickupLatlng.lng,
    //   drop_latitude: dropLatlng.lat,
    //   drop_longitude: dropLatlng.lng,
    //   guest_email: value.guest_email,
    //   guest_name: value.guest_name,
    //   guest_phone: value.guest_phone,
    //   guest_country_code: value.guest_country_code
    // };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/updateEditpageCorporate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByEditUpdateSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function nearestDriver(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      tripId: value
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/nearestDriverList`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getByNearestDriverSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function actionDriveData(driverValue, tripId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      driverDetails: driverValue,
      tripId: tripId
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/driverAction`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByActionDriverDataSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function assignDriver(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/sendDriverReqest`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByAssignDriverDataSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPassenger(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/passengerAdd`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByAddPassengerSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editPassenger(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/passengerEdit`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByEditPassengerSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Exit record

export function getExitRecord() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        'http://golocation-e430b3a3bd6ca08e.elb.me-south-1.amazonaws.com:8809/api/v1/listOfExitRecord'
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getExitRecordInfoSucess(response.data.responce));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverDocument() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/georeport/driverDocuments`
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getDriverDocumentSucess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverFilterDocument(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      fromDate: value.fromDate + ' 00:00:00',
      toDate: value.toDate + ' 23:59:59'
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/georeport/filterDriverDocuments`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getDriverFilterDocumentSucess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverFilterDocumentStatus(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      // const response = await axios.post(
      //   'https://passnode.limor.us/passenger/documentStatusUpdate',
      //   value
      // );
      const response = await axios.post(
        'https://ridenode.limor.us/passenger/documentStatusUpdate',
        value
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getDriverFilterDocumentStatusSucess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function passengerRecords(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      value: value,
      company_id: 7
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/passengerList`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getByPassengerDataSucess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function corporateRecords(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      value: value,
      company_id: 7
    };
    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/corporateListAll`,
        obj
      );
      if (response.data.responseDate !== undefined) {
        dispatch(
          slice.actions.getByCorporateDataSucess(response.data.responseDate)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function packageRecords(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/packageListAll`,
        value
      );
      if (response.data.responseDate !== undefined) {
        dispatch(
          slice.actions.getByPackageDataSucess(response.data.responseDate)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function MotorCarList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/motorModelList`,
        value
      );
      if (response.data.responseDate !== undefined) {
        dispatch(
          slice.actions.getByMotorCarListSucess(response.data.responseDate)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function SaveBooking(value, fareValue, dates, directions) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());

//     const obj = {
//       approx_distance: value.approx_distance,
//       approx_duration: value.approx_duration,
//       approx_trip_fare: fareValue >= 0 ? fareValue : 0,
//       cityname: '',
//       distance_away: 0,
//       drop_latitude: value.drop_latitude,
//       drop_longitude: value.drop_longitude,
//       drop_makani: '',
//       drop_notes: '',
//       dropplace: value.dropLocation,
//       fav_driver_booking_type: 0,
//       // friend_id1: value.passenger_info.passenger_id
//       //   ? value.passenger_info.passenger_id
//       //   : value.corporate_info.corporate_id
//       //   ? value.corporate_info.corporate_id
//       //   : '',
//       // friend_id2: 0,
//       // friend_id3: 0,
//       // friend_id4: 0,
//       // friend_percentage1: 100,
//       // friend_percentage2: 0,
//       // friend_percentage3: 0,
//       // friend_percentage4: 0,
//       guest_name: value.guest_name,
//       guest_phone: value.guest_phone,
//       guest_email: value.guest_email,
//       guest_country_code: value.guest_country_code,
//       is_guest_booking: value.is_guest_booking,
//       latitude: value.latitude,
//       longitude: value.longitude,
//       motor_model: value.motor_model_info.motor_model,
//       notes: '',
//       now_after: value.bookingtype,
//       // passenger_id: value.passenger_info.passenger_id
//       //   ? value.passenger_info.passenger_id
//       //   : '',
//       corporate_id: value.corporate_id,
//       corporate_share:
//         value.zone_fare_applied === 1 ? value.corporate_share : 0,
//       // passenger_payment_option: 'C',
//       passenger_payment_option: parseInt(value.payment_method),
//       payment_mode: '1',
//       pickupplace: value.pickupLocation,
//       pickupLandmark: value.pickupLandmark,
//       dropLandmark: value.dropLandmark,
//       pickup_makani: '',
//       pickup_notes: '',
//       pickup_time: dates,
//       promo_code: value.promocode,
//       payment_by: value.payment_by,
//       package_type:
//         value.package_type !== '' ? parseInt(value.package_type) : '',
//       package_id: value.package_id,
//       request_type: '1',
//       company_id: value.company_id,
//       sub_logid: '',
//       note_to_driver: value.note_to_driver,
//       note_to_admin: value.note_to_admin,
//       flight_number: value.flight_number,
//       reference_number: value.reference_number,
//       customer_price: value.zone_fare_applied === 1 ? value.customer_price : 0,
//       customer_hourly_rate: value.customer_hourly_rate,
//       driver_price: value.driver_price,
//       driver_hourly_rate: value.driver_hourly_rate,
//       route_polyline: directions,
//       customer_rate: value.customer_rate,
//       rsl_share: value.zone_fare_applied === 1 ? value.rslShare : 0,
//       driver_share: value.zone_fare_applied === 1 ? value.driverShare : 0,
//       room_number:value.room_number,
//       operational_supervisor:value.operational_supervisor,
//       extra_charge:
//         value.extra_charge !== '' ? parseInt(value.extra_charge) : undefined,
//       remarks: value.remarks !== '' ? value.remarks : undefined,
//       zone_fare_applied: value.zone_fare_applied,
//       pickup_zone_id:
//         value.zone_fare_applied === 1 ? value.pickup_zone_id : undefined,
//       pickup_zone_group_id:
//         value.zone_fare_applied === 1 ? value.pickup_zone_group_id : undefined,
//       drop_zone_id:
//         value.zone_fare_applied === 1 ? value.drop_zone_id : undefined,
//       drop_zone_group_id:
//         value.zone_fare_applied === 1 ? value.drop_zone_group_id : undefined
//     };

//     // console.log(obj);

//     try {
//       // const response = await axios.post(
//       //   'https://ridedemo1.limor.us/passengerapi121/index/?type=savebookingWeb&dt=a&v=120&vn=9.4.0&lang=en&dID=f0d0179e8d17afa4',
//       //   obj
//       // );
//       const response = await axios.post(
//         `${newBookingUrl}/newBooking/saveBookingCorporateWeb`,
//         obj
//       );

//       if (response.data !== undefined) {
//         console.log('response', response.data);
//         dispatch(slice.actions.getBySaveBookingSucess(response.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function SaveBooking(value, fareValue, dates, directions) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      approx_distance: value.approx_distance,
      approx_duration: value.approx_duration,
      approx_trip_fare: fareValue >= 0 ? fareValue : 0,
      cityname: '',
      distance_away: 0,
      drop_latitude: value.drop_latitude,
      drop_longitude: value.drop_longitude,
      drop_makani: '',
      drop_notes: '',
      dropplace: value.dropLocation,
      fav_driver_booking_type: 0,
      // friend_id1: value.passenger_info.passenger_id
      //   ? value.passenger_info.passenger_id
      //   : value.corporate_info.corporate_id
      //   ? value.corporate_info.corporate_id
      //   : '',
      // friend_id2: 0,
      // friend_id3: 0,
      // friend_id4: 0,
      // friend_percentage1: 100,
      // friend_percentage2: 0,
      // friend_percentage3: 0,
      // friend_percentage4: 0,
      guest_name: value.guest_name,
      guest_phone: value.guest_phone,
      guest_email: value.guest_email,
      guest_country_code: value.guest_country_code,
      is_guest_booking: value.is_guest_booking,
      latitude: value.latitude,
      longitude: value.longitude,
      motor_model: value.motor_model_info.motor_model,
      car_make_id: value.motor_model_info.car_make_id,
      notes: '',
      now_after: value.bookingtype,
      // passenger_id: value.passenger_info.passenger_id
      //   ? value.passenger_info.passenger_id
      //   : '',
      corporate_id: value.corporate_id,
      corporate_share:
        value.zone_fare_applied == 1 ? value.corporate_share : 0,
      // passenger_payment_option: 'C',
      passenger_payment_option: value.payment_method,
      payment_mode: '1',
      pickupplace: value.pickupLocation,
      pickupLandmark: value.pickupLandmark,
      dropLandmark: value.dropLandmark,
      pickup_makani: '',
      pickup_notes: '',
      pickup_time: dates,
      promo_code: value.promocode,
      payment_by: value.payment_by,
      package_type:
        value.package_type !== '' ? parseInt(value.package_type) : '',
      package_id: value.package_id !== '' ? parseInt(value.package_id) : '',
      request_type: '1',
      company_id: value.company_id,
      sub_logid: '',
      note_to_driver: value.note_to_driver,
      note_to_admin: value.note_to_admin,
      flight_number: value.flight_number,
      reference_number: value.reference_number,
      customer_price: value.zone_fare_applied == 1 ? value.customer_price : 0,
      customer_hourly_rate: value.customer_hourly_rate,
      driver_price: value.driver_price,
      driver_hourly_rate: value.driver_hourly_rate,
      route_polyline: directions,
      customer_rate: value.customer_rate,
      rsl_share: value.zone_fare_applied == 1 ? value.rslShare : 0,
      driver_share: value.zone_fare_applied == 1 ? value.driverShare : 0,
      room_number:value.room_number,
      operational_supervisor:value.operational_supervisor,
      extra_charge:
        value.extra_charge !== '' ? parseInt(value.extra_charge) : undefined,
      remarks: value.remarks !== '' ? value.remarks : undefined,
      trip_type: value.zone_fare_applied == 1 && value.trip_type !== '' ? value.trip_type : undefined,
      double_the_fare: value.zone_fare_applied == 1 && value.double_the_fare !== '' && value.trip_type == 2 ? value.double_the_fare : undefined,
      zone_fare_applied: value.zone_fare_applied,

      pickup_zone_id:
        value.zone_fare_applied == 1 ? value.pickup_zone_id : undefined,
      pickup_zone_group_id:
        value.zone_fare_applied == 1 ? value.pickup_zone_group_id : undefined,
      drop_zone_id:
        value.zone_fare_applied == 1 ? value.drop_zone_id : undefined,
      drop_zone_group_id:
        value.zone_fare_applied == 1 ? value.drop_zone_group_id : undefined
    };

    // console.log(obj);

    try {
      // const response = await axios.post(
      //   'https://ridedemo1.limor.us/passengerapi121/index/?type=savebookingWeb&dt=a&v=120&vn=9.4.0&lang=en&dID=f0d0179e8d17afa4',
      //   obj
      // );
      const response = await axios.post(
        `${newBookingUrl}/newBooking/saveBookingCorporateWeb`,
        obj
      );

      if (response.data !== undefined) {
        console.log('response', response.data);
        dispatch(slice.actions.getBySaveBookingSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function FareDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        // `${newBookingUrl}/newBooking/motorDetails`,
        `${newBookingUrl}/newBooking/carMakeList`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getByFareDetailsSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllCarMakes(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/allCarMakes`, obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAllCarMakesSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// req Params

// {
//   "corporate_id": 168,
//   "pickup_latitude": 11.0318,
//   "pickup_longitude": 77.0188,
//   "drop_latitude": 11.0247,
//   "drop_longitude": 77.0106,
//   "company_id": 7,
//   "car_make_id": 1,
//   "model_id": 10,
//   "distance": 0
// }

export function getCarMakeFare(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/getCarMakeFare
        `, value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakeFareSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCorpratePackageList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/getCorpratePackageList`, value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCorporatePackageListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function OverViewData(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const Obj = {
      tripId: value
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/overView`,
        Obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getByOverViewSucess(response.data.responseData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function EndTripApi(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        'https://web.limor.us/driverapi119/index/?type=tripEndWeb&gt_lst_time=1664783608',
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getByEndTripSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CurrentDriverLocation(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      UserId: value
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/currentDriverLocation`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByCurrentDriverLocationSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function LocationGroup(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      fromDate: value.fromDate + ' 00:00:00',
      toDate: value.toDate + ' 23:59:59'
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/georeport/locationGroupList`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getLocationGroupSucess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddRecord(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${newBookingUrl}/georeport/addLocationGroup`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAddRecordInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEditRecord(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${newBookingUrl}/georeport/editLocationGroup`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getEditRecordInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function locationStatusUpdate(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/georeport/updateStatusLocationGroup`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getLocationStatusUpdateSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function chatList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        'https://ridenode.limor.us/passenger/getDriverDocumentById',
        value
      );

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getChatListSucess(response.data.responseData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function chatListUpdate(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const formData = new FormData();
    formData.append('id', value.id);
    formData.append('label', value.label);
    formData.append('document', value.document);
    formData.append('user_type', value.user_type);
    formData.append('driver_id', value.driver_id);
    // console.log('formData', formData);
    try {
      const response = await axios.post(
        'https://ridenode.limor.us/passenger/documentStatusUpdateMob',
        formData
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getChatListUpdateSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function DriverSendSms(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/corporateTripReminder`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSendDriverSmsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function LoginForm(email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      email: email.email,
      password: email.password,
      corporate_status: 'A'
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/loginVerify`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getLoginFormSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ResetLoginForm(email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      email: email,
    };

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/forgotPassword`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getResetLoginFormSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeUserPassword(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/changePassword`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getChangeLoginFormSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getProfileDetails(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${newBookingUrl}/newBooking/profile`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getProfileDetailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
